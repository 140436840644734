import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LanguageGuard } from '../shared/guard/language.guard';

const baseRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    data: { metaKey: 'SEO.HOME' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./about-us/about-us.module').then((m) => m.AboutUsModule),
    data: { metaKey: 'SEO.ABOUT_US' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'career',
    loadChildren: () =>
      import('./careers/careers.module').then((m) => m.CareersModule),
    data: { metaKey: 'SEO.CAREER' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./contact-us/contact-us.module').then((m) => m.ContactUsModule),
    data: { metaKey: 'SEO.CONTACT_US' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('./terms-conditions/terms-conditions.module').then(
        (m) => m.TermsConditionsModule
      ),
    data: { metaKey: 'SEO.TERM_AND_CONDITIONS' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
    data: { metaKey: 'SEO.PRIVACY_POLICY' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'idepage',
    loadChildren: () =>
      import('./idepage/idepage.module').then((m) => m.IdepageModule),
    data: { metaKey: 'SEO.IDEPAGE' },
    canActivate: [LanguageGuard]
  },
  // {
  //   path: 'ideshop',
  //   loadChildren: () =>
  //     import('./ideshop/ideshop.module').then((m) => m.IdeshopModule),
  //   data: { metaKey: 'SEO.IDESHOP' }
  // },
  {
    path: 'idepos',
    loadChildren: () =>
      import('./idepos/idepos.module').then((m) => m.IdeposModule),
    data: { metaKey: 'SEO.IDEPOS' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'purchase-summary',
    loadChildren: () =>
      import('./purchase-summary/purchase-summary.module').then(
        (m) => m.PurchaseSummaryModule
      ),
    data: { metaKey: 'SEO.PURCHASE_SUMMARY' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'FAQ',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
    data: { metaKey: 'SEO.FAQ' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'idefood/idefood-pda',
    loadChildren: () =>
      import('./idefood-pda/idefood-pda.module').then(
        (m) => m.IDEFOODPDAModule
      ),
    data: { metaKey: 'SEO.IDEFOOD_PDA' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
    data: { metaKey: 'SEO.BLOG' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'live-chat',
    loadChildren: () =>
      import('./livechat/livechat.module').then((m) => m.LivechatModule),
    canActivate: [LanguageGuard]
  },
  {
    path: 'tickets',
    loadChildren: () =>
      import('./tickets/tickets.module').then((m) => m.TicketsModule),
    canActivate: [LanguageGuard]
  },
  {
    path: 'schedule-demo',
    loadChildren: () =>
      import('./schedule-demo/schedule-demo.module').then(
        (m) => m.ScheduleDemoModule
      ),
    data: { metaKey: 'SEO.SCHEDULE_DEMO' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'promo',
    loadChildren: () =>
      import('./promo/promo.module').then((m) => m.PromoModule),
    data: { metaKey: 'SEO.PROMO' },
    canActivate: [LanguageGuard]
  },
  // {
  //   path: 'rental',
  //   loadChildren: () => import('./rent/rent.module').then((m) => m.RentModule),
  // },
  {
    path: 'pos-system-solution-singapore',
    loadChildren: () => import('./ads/ads.module').then((m) => m.AdsModule),
    data: { metaKey: 'SEO.POS_SYSTEM_SOLUTION_SINGAPORE' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'reseller-partner-form',
    loadChildren: () =>
      import('./reseller-partner/reseller-partner.module').then(
        (m) => m.ResellerPartnerModule
      ),
    data: { metaKey: 'SEO.RESELLER_PARTNER_FORM' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'integration/delivery/grabfood',
    loadChildren: () => import('./grab/grab.module').then((m) => m.GrabModule),
    data: { metaKey: 'SEO.GRABFOOD' } ,
    canActivate: [LanguageGuard]
  },
  {
    path: 'idefood/idefood-qr',
    loadChildren: () =>
      import('./idefood-qr/idefood-qr.module').then((m) => m.IdefoodQrModule),
    data: { metaKey: 'SEO.IDEFOOD_QR' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'ideque',
    loadChildren: () =>
      import('./ideque/ideque.module').then((m) => m.IdequeModule),
    data: { metaKey: 'SEO.IDEQUE' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'idekiosk',
    loadChildren: () =>
      import('./idekiosk/idekiosk.module').then((m) => m.IdekioskModule),
    data: { metaKey: 'SEO.IDEKIOSK' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'kds-eds',
    loadChildren: () =>
      import('./kds-eds/kds-eds.module').then((m) => m.KDSEDSModule),
    data: { metaKey: 'SEO.KDS_EDS' },
    canActivate: [LanguageGuard]
  },
  {
    path: 'integration/payment/stripe',
    loadChildren: () => import('./stripe/stripe.module').then((m) => m.StripeModule),
    data: { metaKey: 'SEO.STRIPE' },
    canActivate: [LanguageGuard]
  },
];

export const routes: Routes = [
 ...baseRoutes,
  {
    path: 'en',
    children: [
      ...baseRoutes
    ],
  },
  {
    path: 'id',
    children: [
      ...baseRoutes
    ],
  },
  {
    path: 'zh-TW',
    children: [
      ...baseRoutes
    ],
  },
   {
    path: 'zh-CN',
    children: [
      ...baseRoutes
    ],
  }
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(routes);
