import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LanguageGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const url = state.url;
    const regex = /^\/(en|id|zh-TW|zh-CN)/;
    console.log(url)
    if (!regex.test(url)) {
      // Redirect to the default language route
      if (url !== '/') {
        this.router.navigateByUrl('/en' + url);
      } else {
        this.router.navigateByUrl('/en');
      }
      return false;
    }
    
    return true;
  }
}
