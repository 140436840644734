import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private titleService: Title, private metaService: Meta, private translate: TranslateService) { }

  updateMetaTags(key: string) {
    this.translate.get([`${key}.TITLE`, `${key}.DESCRIPTION`]).subscribe(translations => {
      console.log(translations)
      this.titleService.setTitle(translations[`${key}.TITLE`]);
      this.metaService.updateTag({ name: 'description', content: translations[`${key}.DESCRIPTION`] });
    });
  }

  updateKeywords() {
    this.translate.get('SEO.KEYWORDS_ALL').subscribe((keywords: string) => {
      this.metaService.updateTag({ name: 'keywords', content: keywords });
    });
  }
}
